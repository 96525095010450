































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {email, required} from "vuelidate/lib/validators";


Component.registerHooks(['validations'])
@Component({
  components: {SygniContainerTitle, SygniCheckbox, SygniLine, SygniRadio, SygniRoundedButton, SygniInput}
})
export default class ResetPassword extends Vue {

  email: string = '';
  isResetPasswordSent: boolean = false;

  validations (){
    return {
        email: { required, email },
      }
  }

  goToLogin() {
    this.$router.push('/auth/login');
  }

  async sendRequestResetPassword(){
    this.$v.$touch();
    if(!this.$v.$error){
      try{
        await this.$store.dispatch('auth/sendRemindPasswordRequest', this.email);
        this.isResetPasswordSent = true;
      } catch (e) {
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(e.response.data.label).toString()
        });
      }
      this.$v.$reset();
      this.email = '';
    }
  }

}
